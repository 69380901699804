import React, { useEffect, useState } from "react";
import { PromptSnippet } from "../../models/promptsnippet";
import { SnippetButton, WSPRule } from "../../components";
import { SnippetType } from "../../enums/SnippetType";
import { ChatContextType } from "../../contexts/ChatContext/ChatContextType";
import { ChatContext } from "../../contexts/ChatContext/ChatContextProvider";
import { useNavigate } from "react-router-dom";
import { FilterPill } from "../../components";
import {
  base,
  conference_proceedings,
  knowledge_base,
  mine_sites,
  promptSnippets,
  promptGroup1,
  promptGroup2,
  promptGroup3,
} from "../../data";
import ImageFilterPill from "../../components/imagefilterpill/imagefilterpill";
import { SearchType } from "../../enums/SearchTypes";
import { FilterScope } from "../../enums/FilterScope";

const Home = () => {
  const chatContext = React.useContext<ChatContextType | null>(ChatContext);
  const [inputValue, setInputValue] = useState<string>(
    chatContext ? chatContext!.chat.question : ""
  );
  const [submitReady, setSubmitReady] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState<boolean>(
    chatContext ? chatContext!.allSelected : true
  );
  const [appliedFilters, setAppliedFilters] = useState<string[]>(
    chatContext ? chatContext!.indexes : []
  );

  const [openFilters, setOpenFilters] = useState<string[]>([]);
  const [filterScope, setFilterScope] = useState<FilterScope>();
  const navigate = useNavigate();

  useEffect(() => {
    if (inputValue.trim().length > 0) {
      setSubmitReady(true);
    } else {
      setSubmitReady(false);
    }
  }, [inputValue]);

  const handleUserChange = (value: string) => {
    setInputValue(value);
  };

  const handleAddToPrompt = (value: string) => {
    setInputValue(value);
  };

  const handleSubmit = () => {
    chatContext?.saveChatAndIndexes(
      { question: inputValue.trim(), history: "" },
      appliedFilters,
      filterScope
    );
    navigate("/chat");
  };

  const addToAppliedFilters = (value: string, startFresh: boolean) => {
    let newFilters = [...appliedFilters];

    if (startFresh) {
      newFilters = [];
    }

    const newValues = value.split(",");

    newValues.forEach((newValue) => {
      const index = newFilters.indexOf(newValue, 0);
      if (index < 0) {
        newFilters.push(newValue);
      }
    });

    setAppliedFilters(newFilters);
    chatContext?.saveIndexes(newFilters);
  };

  const removeFromAppliedFilters = (value: string) => {
    const newFilters = [...appliedFilters];

    const newValues = value.split(",");
    newValues.forEach((newValue) => {
      do {
        const index = newFilters.indexOf(newValue, 0);
        if (index > -1) {
          newFilters.splice(index, 1);
        }
      } while (newFilters.indexOf(newValue, 0) > -1);
    });

    if (newFilters.length < 1) {
      setAppliedFilters(base.map((item) => item.indexName));
      chatContext?.saveAllSelected(true);
      setAllSelected(true);
    } else {
      setAppliedFilters(newFilters);
    }

    chatContext?.saveIndexes(newFilters);
  };

  const saveFilterScope = (scope: FilterScope) => {
    chatContext?.saveFilterScope(scope);
  };

  const isAllResources = () => {
    const baseLength = base.length;
    let matchLength = 0;

    base.forEach((element) => {
      const result = appliedFilters.filter(
        (filter) => filter === element.indexName
      );
      if (result.length > 0) {
        matchLength = matchLength + 1;
      }
    });

    return baseLength === matchLength;
  };

  const closeAllFilters = () => {
    setOpenFilters([]);
  };

  return (
    <div className="flex flex-col">
      <div className="bg-light-blue py-10 bg-[url('/public/bg.png')] bg-cover bg-center bg-no-repeat">
        <div className="mx-auto w-4/5 bg-light-gray-transparent py-8 px-8">
          <div className="pb-6">
            <h2 className="text-red">
              Welcome to the Foundation Model,
              <br />
              <b>ask anything</b> about deep mining.
            </h2>
          </div>
          <div className="mb-2">
            <div className="flex flex-row gap-2">
              <div>
                <button
                  type="button"
                  className={
                    chatContext?.searchType === SearchType.All
                      ? "px-2 py-4 font-semibold border-b-4 border-blue"
                      : "px-2 py-4 font-semibold"
                  }
                  onClick={() => chatContext?.saveSearchType(SearchType.All)}
                >
                  All
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className={
                    chatContext?.searchType === SearchType.Images
                      ? "px-2 py-4 font-semibold border-b-4 border-blue"
                      : "px-2 py-4 font-semibold"
                  }
                  onClick={() => chatContext?.saveSearchType(SearchType.Images)}
                >
                  Images
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="bg-white border border-light-gray-40 p-2">
              <div>
                <textarea
                  className="w-full resize-none text-charcoal-dark"
                  rows={5}
                  placeholder="I want to learn/analyze..."
                  value={inputValue}
                  onChange={(e) => handleUserChange(e.target.value)}
                  onFocus={() => closeAllFilters()}
                ></textarea>
              </div>
              <div className="flex flex-row text-charcoal-dar">
                <div className="flex flex-1 text-xs m-auto gap-1 flex-wrap">
                  <div className="my-auto">Filters:</div>

                  {/* All Resources */}
                  <div
                    className={
                      allSelected
                        ? "border border-blue bg-blue text-white rounded-full my-auto"
                        : "border border-blue rounded-full my-auto"
                    }
                  >
                    <button
                      type="button"
                      className="p-2"
                      onClick={() => {
                        setAllSelected(true);
                        chatContext?.saveAllSelected(true);
                        setAppliedFilters(base.map((item) => item.indexName));
                      }}
                    >
                      All Resources
                    </button>
                  </div>

                  {/* Divider */}
                  <div className="border-r border-light-gray pl-1 mr-1 my-1"></div>

                  <FilterPill
                    pillname="WSP Documents"
                    data={mine_sites}
                    appliedFilters={appliedFilters}
                    addCallback={addToAppliedFilters}
                    removeCallback={removeFromAppliedFilters}
                    openFilters={openFilters}
                    setOpenFilters={setOpenFilters}
                    allSelected={allSelected}
                    setAllSelected={(value: boolean) => {
                      setAllSelected(value);
                      chatContext?.saveAllSelected(value);
                    }}
                  />

                  {/* Conference Proceedings 
                  <FilterPill
                    pillname="Conference Proceedings"
                    data={conference_proceedings}
                    appliedFilters={appliedFilters}
                    addCallback={addToAppliedFilters}
                    removeCallback={removeFromAppliedFilters}
                    openFilters={openFilters}
                    setOpenFilters={setOpenFilters}
                    allSelected={allSelected}
                    setAllSelected={(value: boolean) => {
                      setAllSelected(value);
                      chatContext?.saveAllSelected(value);
                    }}
                  />
                  */}

                  {/* Mining Knowledge Base
                  <FilterPill
                    pillname="Mining Knowledge Base"
                    data={knowledge_base}
                    appliedFilters={appliedFilters}
                    addCallback={addToAppliedFilters}
                    removeCallback={removeFromAppliedFilters}
                    openFilters={openFilters}
                    setOpenFilters={setOpenFilters}
                    allSelected={allSelected}
                    setAllSelected={(value: boolean) => {
                      setAllSelected(value);
                      chatContext?.saveAllSelected(value);
                    }}
                  />
                  */}
                  
                  {/* Divider */}
                  {chatContext?.searchType === SearchType.All && (
                    <div className="border-r border-light-gray pl-1 mr-1 my-1"></div>
                  )}

                  {chatContext?.searchType === SearchType.All && (
                    <ImageFilterPill
                      pillname="Image Filter Scope"
                      openFilters={openFilters}
                      setOpenFilters={setOpenFilters}
                      saveCallback={saveFilterScope}
                    />
                  )}
                </div>
                <div className="mt-auto">
                  <button
                    type="button"
                    className={
                      submitReady
                        ? "bg-blue text-white w-full p-2"
                        : "bg-disabled text-white w-full p-2"
                    }
                    disabled={!submitReady}
                    onClick={handleSubmit}
                  >
                    <svg
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.925 3.025L8.18333 5.70833L1.91667 4.875L1.925 3.025ZM8.175 10.2917L1.91667 12.975V11.125L8.175 10.2917ZM0.258333 0.5L0.25 6.33333L12.75 8L0.25 9.66667L0.258333 15.5L17.75 8L0.258333 0.5Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="py-4">Get started with these prompt snippets.</div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {promptSnippets.map((snippet, i) => (
              <div key={i}>
                <SnippetButton
                  snippet={snippet}
                  type={SnippetType.Existing}
                  callback={handleAddToPrompt}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mx-auto w-4/5">
        <div className="grid grid-cols-1 gap-4">
          <div className="pt-8">
            <h5 className="text-red">Explore The Foundation Model</h5>
          </div>
          <div className="font-semibold	">
            Example Questions
            <WSPRule />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {promptGroup1.map((snippet, i) => (
              <div key={i}>
                <SnippetButton
                  snippet={snippet}
                  type={SnippetType.New}
                  callback={handleAddToPrompt}
                />
              </div>
            ))}
          </div>
          <div className="font-semibold	">
            How Tos
            <WSPRule />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {promptGroup2.map((snippet, i) => (
              <div key={i}>
                <SnippetButton
                  snippet={snippet}
                  type={SnippetType.New}
                  callback={handleAddToPrompt}
                />
              </div>
            ))}
          </div>
          <div className="font-semibold	">
            Project Spotlights
            <WSPRule />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {promptGroup3.map((snippet, i) => (
              <div key={i}>
                <SnippetButton
                  snippet={snippet}
                  type={SnippetType.New}
                  callback={handleAddToPrompt}
                />
              </div>
            ))}
          </div>
          <div className="pb-2"></div>
        </div>
      </div>
    </div>
  );
};

export default Home;
